<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ $store.state.pageTitle }}</v-toolbar-title>
    </template>

    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col cols="12" xs="12" md="4" sm="12" lg="4" xl="4">
          <v-card class="mb-2" outlined elevation="4">
            <v-card-title class="py-1">Informasi</v-card-title>
            <v-divider></v-divider>
            <v-list two-line>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>NIS</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  payment.detail.nis
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Siswa</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  payment.detail.fullname
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Orang Tua / Wali</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  payment.detail.parents_fullname
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Kode</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  payment.detail.payment_type_code
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Deskripsi</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  payment.detail.payment_type_description
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px" v-if="payment.detail.remarks">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Keterangan</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  (payment.detail.remarks!='') ? payment.detail.remarks : '-'
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px" v-if="payment.detail.year_text">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Th. Akademik</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  (payment.detail.year_text!='') ? payment.detail.year_text : '-'
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px" v-if="payment.detail.period_year">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Tahun</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{
                  (payment.detail.period_year!='') ? payment.detail.period_year : '-'
                }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height:32px">
                <v-list-item-content class="py-0 my-1">
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle class="text-left">
                  <v-chip :color="payment.detail.paid_off == 1 ? 'green' : 'red'" dark>{{
                    payment.detail.paid_off_info
                  }}</v-chip></v-list-item-subtitle>
                    </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="12" lg="8" xl="8">
          <v-card class="mb-2" outlined elevation="4">
            <v-card-title>Informasi Pembayaran</v-card-title>
            <v-list>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Kode</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.code
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Tipe</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.payment_type_description
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Tanggal Pembayaran</v-list-item-title>
                <v-list-item-subtitle class="text-right" v-if="payment.detail.paid==1">{{
                  appDateFormat(payment.detail.payment_date)
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Metode</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.payment_method
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px" v-if="(payment.detail.payment_type_cycle='yearly') || (payment.detail.payment_type_cycle='monthly')">
                <v-list-item-title>Tahun</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.payment_year
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px" v-if="payment.detail.payment_type_cycle='monthly'">
                <v-list-item-title>Bulan</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.payment_month_name
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Keterangan</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.remarks
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 34px">
                <v-list-item-title>Jumlah</v-list-item-title>
                <v-list-item-subtitle class="text-right font-weight-bold text-h6"
                  >Rp.
                  {{
                    numberFormat(payment.detail.amount, 2)
                  }}</v-list-item-subtitle
                >
              </v-list-item>
            </v-list>
          </v-card>
          <v-card class="mb-2" outlined elevation="4">
            <v-card-title>Informasi Tambahan</v-card-title>
            <v-list>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Di Input Tanggal</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  appDateFormat(payment.detail.input_date)
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 24px">
                <v-list-item-title>Di input oleh</v-list-item-title>
                <v-list-item-subtitle class="text-right">{{
                  payment.detail.input_by_name
                }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item style="min-height: 36px">
                <v-list-item-title>Status</v-list-item-title>
                <v-list-item-subtitle class="text-right text--body-2">
                    <v-chip :color="payment.detail.paid == 1 ? 'green' : 'red'" dark>{{
                      payment.detail.paid_info
                    }}</v-chip>
                  </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "PaymentInfo",
  components: { DefaultLayout },

  data: () => ({
    payment: {
      detail: {},
    },
  }),

  methods: {
    async getInfo() {
      this.$store.state.loadingDialog = true;
      await this.$axios
        .get("payment/info", {
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.$store.state.showPageOverlay = false;
          if (res.data.status == "success") {
            this.payment = res.data.data;
          } else {
            this.payment = {};
          }
        });
    },
  },

  beforeMount() {
    if (this.$store.state.isLoggedIn == false) {
      this.$router.push("/login");
    }
  },

  mounted() {
    this.getInfo();
    this.setPageTitle("Informasi Pembayaran", "mdi-cash");
  },
};
</script>