<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click="$refs.myRightBar.show()">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </template>
    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-2"></v-divider>
      <v-row>
        <v-col>
          <v-tabs v-model="paymentType" :show-arrows="true">
            <v-tab
              v-for="(pt, i) in paymentTypeList"
              :key="i"
              @click="setSelectedPaymentTypeId(pt.id)"
              >{{ pt.description }}</v-tab
            >
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-bind="attrs"
            v-if="loading"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-bind="attrs"
            v-if="loading"
            type="list-item-two-line"
          ></v-skeleton-loader>
          <v-skeleton-loader
            v-bind="attrs"
            v-if="loading"
            type="list-item-two-line"
          ></v-skeleton-loader>

          <div v-if="loading==false && paymentList.length==0"><p class="py-4">Oops.. data tidak dimukan, silahkan filter <a  @click="$refs.myRightBar.show()">disini</a>.</p></div>

          <div v-if="loading != true">
            <v-card
              v-for="(p, ii) in paymentList"
              :key="ii"
              class="mb-2"
              elevation="2"
              outlined
              @click="viewDetail(p.id)"
            >
              <v-card-title class="mb-1 pb-0">{{
                p.payment_type_description
              }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-row class="my-0">
                      <v-col v-if="p.fullname" class="py-0 text-truncate">{{
                        p.fullname
                      }}</v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col v-if="p.year_text" class="py-0 text-truncate">{{
                        p.year_text
                      }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col class="text-right">
                    <v-chip :color="p.paid_off == 1 ? 'green' : 'red'" dark>{{
                      p.paid_off_info
                    }}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <RightBar ref="myRightBar" title="Filter">
      <v-select
        v-model="selectedStudent"
        :items="students"
        item-text="fullname"
        item-value="id"
        label="Pilih Siswa"
        return-object
        @change="setSelectedStudent"
      ></v-select>

      <v-row class="mt-2">
        <v-col>
          <v-btn class="mr-1" color="primary" @click="setFilter">Filter</v-btn>
          <v-btn @click="resetFilter">Reset</v-btn>
        </v-col>
      </v-row>
    </RightBar>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import RightBar from "@/layouts/RightBar.vue";

export default {
  name: "PaymentList",
  components: { DefaultLayout, RightBar },

  data: () => ({
    payments: [],
    selectedItem: null,
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
    loading: null,
    students: [],
    selectedStudent: {},
    paymentType: {},
    paymentTypeList: [],
    paymentList: [],
    selectedPayment: {},
    academicYear: {},
    academicYearList: [],
    filterText: "",
    selectedPaymentTypeId: 0,
    notFound : null,
  }),
  methods: {
    viewDetail(id) {
      this.$router.push("/payment-list/" + id + "/detail");
    },
    viewInfo(id) {
      this.$router.push("/payment-list/" + id + "/info");
    },
    setSelectedPaymentTypeId(selectedPaymentTypeId) {
      this.selectedPaymentTypeId = selectedPaymentTypeId;
      this.getPaymentList();
    },
    setSelectedStudent() {
      this.$store.state.selectedStudent = this.selectedStudent;
    },
    getChildren() {
      this.$axios.get("student/mychild").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.students = res.data.data.students;
        } else {
          this.students = [];
        }
      });
    },
    setSelectedPaymentType(id) {
      this.selectedPaymentTypeId = id;
    },
    getPaymentType() {
      this.$axios.get("payment/type").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.paymentTypeList = res.data.data;
        } else {
          this.paymentTypeList = [];
        }
      });
    },
    getPaymentList() {
      this.$store.state.showLoadingDialog = true;
      this.loading = true;
      var data = {
        params: {
          student_id: this.selectedStudent.id,
          payment_type_id: this.selectedPaymentTypeId,
          academic_year_id: this.academicYear.id,
        },
      };
      this.$axios
        .get("payment/list", data)
        .then((res) => {
          this.$store.state.showLoadingDialog = false;
          this.loading = false;
          if (res.data.status == "success") {
            this.paymentList = res.data.data;
          } else {
            this.paymentList = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.showAlert("error", "Internal Server Error!");
          this.$store.state.showLoadingDialog = false;
          this.loading = false;
        })
        .finally(() => (this.$store.state.showLoadingDialog = false));
    },

    setFilter() {
      this.$store.state.selectedStudent = this.selectedStudent;
      this.getPaymentList();

      this.$refs.myRightBar.hide();
    },

    resetFilter() {
      this.filterText = "";
      this.getPaymentList();

      this.$refs.myRightBar.hide();
    },
  },

  mounted() {
    this.selectedStudent = this.$store.state.selectedStudent;
    this.getChildren();
    this.getPaymentType();
    this.getPaymentList();
    this.setPageTitle("Daftar Pembayaran", "mdi-cash");
  },
};
</script>