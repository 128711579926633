<template>
  <DefaultLayout>
    <template v-slot:toolbar>
      <v-btn icon @click="$router.back()">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>{{ $store.state.pageTitle }}</v-toolbar-title>
    </template>
    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">{{ $store.state.pageIcon }}</v-icon>
        {{ $store.state.pageTitle }}
      </div>
      <v-divider class="mb-2"></v-divider>

      <v-row>
        <v-col cols="12" xs="12" md="4" sm="12" lg="4" xl="4">
          <v-card class="mb-2" outlined elevation="4">
            <v-card-title class="py-1">Informasi</v-card-title>
            <v-divider></v-divider>
            <v-list two-line>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>NIS</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.nis
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Siswa</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.fullname
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Orang Tua / Wali</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.parents_fullname
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Kode</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.payment_type_code
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Deskripsi</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.payment_type_description
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="min-height: 32px"
                v-if="payment.header.remarks"
              >
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Keterangan</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.remarks != "" ? payment.header.remarks : "-"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="min-height: 32px"
                v-if="payment.header.year_text"
              >
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Th. Akademik</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.year_text != ""
                      ? payment.header.year_text
                      : "-"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                style="min-height: 32px"
                v-if="payment.header.period_year"
              >
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Tahun</v-list-item-title>
                  <v-list-item-subtitle class="text-left">{{
                    payment.header.period_year != ""
                      ? payment.header.period_year
                      : "-"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item style="min-height: 32px">
                <v-list-item-content class="py-0 my-1">
                  <v-list-item-title>Status</v-list-item-title>
                  <v-list-item-subtitle class="text-left">
                    <v-chip
                      :color="payment.header.paid_off == 1 ? 'green' : 'red'"
                      dark
                      >{{ payment.header.paid_off_info }}</v-chip
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" sm="12" lg="8" xl="8">
          <template v-if="payment.detail.length >= 1">
            <v-card
              v-for="(p, ii) in payment.detail"
              :key="ii"
              class="mb-2"
              elevation="2"
              outlined
              @click="viewInfo(p.payment_detail_id)"
            >
              <v-card-title class="mb-1 pb-0">{{ p.code }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-row class="my-0" v-if="p.paid == 1 && p.payment_type_cycle!='one_time_payment'">
                      <v-col class="py-0 text-truncate">Untuk pembayaran {{
                        p.payment_month_name + " " + p.payment_year
                      }}</v-col>
                    </v-row>
                    <v-row class="my-0">
                      <v-col
                        v-if="p.amount"
                        class="py-0 text-truncate font-weight-bold"
                        >Rp. {{ numberFormat(p.amount, 2) }}</v-col
                      >
                    </v-row>
                    <v-row class="my-0">
                      <v-col v-if="p.remarks" class="py-0 text-truncate">{{
                        p.remarks
                      }}</v-col>
                    </v-row>
                  </v-col>
                  <v-col class="text-right">
                    <v-chip :color="p.paid == 1 ? 'green' : 'red'" dark>{{
                      p.paid_info
                    }}</v-chip>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </template>
          <template v-if="payment.detail.length == 0">
            <div class="text-center">
              <p>Data pembarayan Anda akan tampil disini.</p>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "PaymentDetail",
  components: { DefaultLayout },

  data: () => ({
    payment: {
      header: {},
      detail: [],
    },
  }),
  methods: {
    viewInfo(id) {
      this.$router.push("/payment-list/" + id + "/info");
    },
    getPaymentList() {
      this.payment = {
        header: {},
        detail: [],
      };
      this.$store.state.showLoadingDialog = true;
      var data = {
        params: {
          id: this.$route.params.id,
        },
      };
      this.$axios
        .get("payment/detail", data)
        .then((res) => {
          this.$store.state.showLoadingDialog = false;
          if (res.data.status == "success") {
            this.payment = res.data.data;
          } else {
            this.payment = {
              header: {},
              detail: [],
            };
          }
        })
        .catch((error) => {
          console.log(error);
          this.showAlert("error", "Internal Server Error!");
          this.$store.state.showLoadingDialog = false;
        })
        .finally(() => (this.$store.state.showLoadingDialog = false));
    },
  },

  mounted() {
    this.getPaymentList();
    this.setPageTitle("Detil Pembayaran", "mdi-cash");
  },
};
</script>