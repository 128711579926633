<template>
  <DefaultLayout>
    <template slot="toolbar">
      <slot name="appDrawer"></slot>
      <v-toolbar-title class="pl-1">
        <v-icon v-if="$store.state.pageIcon" class="pb-1 pr-2">{{
          $store.state.pageIcon
        }}</v-icon>
        <span>{{ $store.state.pageTitle }}</span>
      </v-toolbar-title>
    </template>
    <v-container>
      <div class="text-h6 ml-4 mb-2">
        <v-icon color="blue">mdi-book</v-icon> {{ subjects.length }} Mata
        Pelajaran
      </div>
      <v-divider></v-divider>
        <v-select
          v-model="selectedStudent"
          :items="students"
          item-text="firstname"
          item-value="id"
          label="Pilih Siswa"
          return-object
          class="mx-2"
          @change="setSelectedStudent"
        ></v-select>

        <v-skeleton-loader
          v-bind="attrs"
          v-if="loading"
          type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
        ></v-skeleton-loader>

      <v-list denase height="100%" width="100%" class="mx-0">
        <v-list-item-group color="primary">
          <v-list-item v-for="(subject, i) in subjects" :key="i" two-line>
            <v-list-item-avatar>
              <v-avatar color="blue" size="36">
                <span class="white--text text-h6">{{
                  subject.avatar_text
                }}</span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="subject.name"></v-list-item-title>
              <v-list-item-subtitle
                v-text="subject.teacher.fullname"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-alert color="warning" class="mx-2" v-if="(typeof selectedStudent.id == 'undefined') && (loading == false) && (subjects.length == 0)">Silahkan pilih siswa terlebih dahulu</v-alert>
      <v-alert color="warning" class="mx-2" v-if="(typeof selectedStudent.id !== 'undefined') && (loading == false) && (subjects.length == 0)">Oppss.. Belum ada data.</v-alert>
    </v-container>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/DefaultLayout.vue";

export default {
  name: "SubjectsList",
  components: { DefaultLayout },

  data: () => ({
    subjects: [],
    loading: null,
    students: [],
    selectedStudent: {},
    attrs: {
      class: "mb-6",
      boilerplate: true,
      elevation: 2,
    },
  }),
  methods: {
    getList() {
      this.loading = true
      var data = {
        params: {
          student_id: this.selectedStudent.id
        }
      }
      this.$axios.get("subjects/list", data).then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.subjects = res.data.data;
        } else {
          this.subjects = [];
        }
        this.loading = false
      });
    },
    setSelectedStudent(){
      this.$store.state.selectedStudent = this.selectedStudent
      this.getList()
    },
    getChildren() {
      this.loading = true;
      this.$axios.get("student/mychild").then((res) => {
        this.$store.state.showLoadingDialog = false;
        if (res.data.status == "success") {
          this.students = res.data.data.students;
        } else {
          this.students = [];
        }
        this.loading = false;
      });
    },
  },

  mounted() {
    this.selectedStudent = this.$store.state.selectedStudent
    this.getList();
    this.getChildren();
    this.setPageTitle("Daftar Pelajaran", "mdi-book");
  },
};
</script>